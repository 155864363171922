// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverUrl: 'https://us-central1-super-bot-1be85.cloudfunctions.net',
  tescoUrl: 'https://dev.tescolabs.com/grocery/products',
  tescoSubscriptionKey: '8a65b121dc274da09519a224b2823b5d',
  firebase: {
    apiKey: 'AIzaSyC_TUNi3UmgO0f5cICCrF7IpKMxDOQCD30',
    authDomain: 'super-bot-1be85.firebaseapp.com',
    databaseURL: 'https://super-bot-1be85.firebaseio.com',
    projectId: 'super-bot-1be85',
    storageBucket: 'super-bot-1be85.appspot.com',
    messagingSenderId: '120362831670'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../authentication.service';
import {Router} from '@angular/router';
import {UserProfileService} from '../user-profile/user-profile.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public isLogin: boolean;
  public loginForm: FormGroup;
  public signupForm: FormGroup;
  public isError: boolean;

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private router: Router,
              private userProfileService: UserProfileService) {
    this.isLogin = true;
  }

  ngOnInit() {
    this.initForm();
  }

  changeState(stat: boolean) {
    this.isLogin = stat;
  }

  login() {
    if (this.loginForm.valid) {
      const formData = this.loginForm.value;
      this.authService.signInWithEmailAndPassword(formData.email, formData.password).then(result => {
        this.router.navigate(['bot']);
      }, error => {
        this.isError = true;
        console.log(error);
      });
    }
  }

  signup() {
    if (this.signupForm.valid) {
      const formData = this.signupForm.value;
      this.authService.createUserWithEmailAndPassword(formData.email, formData.password).then(result => {
        const uid: string = result.user.uid;
        this.userProfileService.createUserProfile(uid, formData.firstName, formData.lastName, formData.email).then(() => {
          this.router.navigate(['bot']);
        });
      }, error => {
        this.isError = true;
        console.log(error);
      });
    }
  }

  private initForm() {
    this.signupForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {AngularFireAuth} from 'angularfire2/auth';
import {Router} from '@angular/router';
import * as firebase from 'firebase/app';
import {first} from 'rxjs/operators';

@Injectable()
export class AuthService {

  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;

  constructor(private _firebaseAuth: AngularFireAuth, private router: Router) {
    this.user = _firebaseAuth.authState;
    this.user.subscribe(
      (user) => {
        if (user) {
          this.userDetails = user;
          console.log(this.userDetails);
        } else {
          this.userDetails = null;
        }
      }
    );
  }

  signInWithEmailAndPassword(email: string, password: string) {
    return this._firebaseAuth.auth.signInWithEmailAndPassword(email, password);
  }

  createUserWithEmailAndPassword(email: string, password: string) {
    return this._firebaseAuth.auth.createUserWithEmailAndPassword(email, password);
  }

  isLoggedIn() {
    return this.user.pipe(first()).toPromise().then(result => {
      return result !== null;
    });
  }

  getCurrentUser() {
    return this.userDetails;
  }

  addListener(path): Observable<any> {
    return Observable.create(observer => {
      const ref = firebase.database(this.getApp()).ref(`${path}`);
      ref.on('value', (snapshot) => {
        observer.next(snapshot.val());
      });
    });
  }

  getItem(path): Promise<any> {
    return new Promise(resolve => {
      const ref = firebase.database(this.getApp()).ref(`${path}`);
      ref.once('value').then(snapshot => {
        resolve(snapshot.val());
      });
    });
  }

  updateItem(data, path) {
    return firebase.database(this.getApp()).ref(`${path}`).update(data);
  }

  deleteItem(path) {
    return firebase.database(this.getApp()).ref(`${path}`).set(null);
  }

  logout() {
    this._firebaseAuth.auth.signOut()
      .then((res) => this.router.navigate(['login']));
  }

  private getApp() {
    return firebase.app('bot-auth');
  }
}

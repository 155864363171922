import {Injectable} from '@angular/core';
import {UserProfile} from './user-profile';
import {AuthService} from '../authentication.service';

@Injectable()
export class UserProfileService {

  constructor(private authService: AuthService) {
  }

  public getUserProfile(uid: string): Promise<UserProfile> {
    return this.authService.getItem(`users/${uid}`);
  }

  public createUserProfile(uid, firstName, lastName, email): Promise<UserProfile> {
    const userProfileData: UserProfile = new UserProfile(uid, firstName, lastName, email);
    return this.authService.updateItem(userProfileData, `users/${uid}`);
  }
}
